<template>
  <div class="group">
    <label>หลักฐานการชำระเงิน</label>
    <div class="contents">
      <img
        v-show="previewImg"
        id="previewImg"
        class="boxUploadImage"
        width="100%"
        :src="previewImg"
      />

      <label for="uploadInput" class="boxUploadImage" v-if="!previewImg">
        <div>
          <img width="17" src="@/assets/img/add.svg" />
          <div>แนบรูปภาพ</div>
        </div>
      </label>
      <input
        type="file"
        ref="fileInput"
        :capture="getMobileOperatingSystem() == 'iOS' ? false : true"
        accept="image/*"
        @change="fileChange"
        id="uploadInput"
      />
      <div v-if="!noFile" class="remove" @click="reset()">ลบไฟล์ที่เลือก</div>
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";

export default {
  props: { imageUrl: String },
  data() {
    return {
      previewImg: this.imageUrl || null,
      uploading: false, // loading
      noFile: true,
      result: {},
    };
  },
  watch: {
    imageUrl(val) {
      this.previewImg = val;
    },
  },
  methods: {
    reset() {
      this.previewImg = null;
      this.uploading = false;
      this.$refs.fileInput.value = null;
      this.noFile = true;
      this.$emit("result", {});
      this.previewImg = this.imageUrl;
    },
    fileChange(e) {
      const file = e.target.files[0];
      this.noFile = !file;

      if (!file) {
        return;
      }

      this.uploading = true;

      if (file.size > 8388608) {
        this.Swal.fire("", "ขนาดไฟล์ไม่ควรเกิน 8MB", "warning");
        this.uploading = false;
        return;
      }

      this.resizeImage(file);
      // this.preview(file);
    },
    preview(file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        this.previewImg = e.target.result;
      };
    },
    resizeImage(file) {
      new Compressor(file, {
        quality: 0.6,
        convertSize: 0,
        maxWidth: 2400,
        maxHeight: 2400,
        success: (result) => {
          // console.log(result);
          this.preview(result);
          this.uploading = false;
          this.result = { file: result, fileName: file.name };
          this.$emit("result", { file: result, fileName: file.name });
        },
        error: (err) => {
          console.log(err.message);
          this.uploading = false;
          this.Swal.fire(
            "",
            "กรุณาตรวจสอบไฟล์ที่เลือกอีกครั้ง หรือติดต่อเจ้าหน้าที่",
            "warning"
          );
        },
      });
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
  },
};
</script>

<style lang="scss" scoped>
.boxUploadImage {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(197, 197, 197);
  text-align: center;
  color: rgb(107, 107, 107);
  font-size: 14px;
  border-radius: 10px;
  background: rgb(231, 231, 231);
  width: 85px;
  height: 85px;
  margin-top: 10px;
  object-fit: cover; /* Do not scale the image */
  object-position: center;
  aspect-ratio: 1/1;
  img {
    margin-bottom: 5px;
  }
}

#uploadInput {
  opacity: 0;
  position: absolute;
  display: none;
  z-index: -1;
}

.contents {
  display: flex;
  align-items: center;
}

.remove {
  margin-left: 15px;
  display: inline;
  border-radius: 6px;
  background: crimson;
  padding: 4px;
  color: rgb(255, 255, 255);
}
</style>