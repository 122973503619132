<template>
  <div class="groupDate">
    <div>
      <select v-model="uDay" id="dayInput" class="form-control" required>
        <option selected disabled value>วว</option>
        <option v-for="n in daysList" :key="'d_' + n" :value="n">
          {{ n >= 10 ? n : "0" + n }}
        </option>
      </select>
    </div>
    <div>
      <select v-model="uMonth" id="monthInput" class="form-control" required>
        <option selected disabled value>ดด</option>
        <option v-for="n in 12" :key="'m_' + n" :value="n">
          {{ n >= 10 ? n : "0" + n }}
        </option>
      </select>
    </div>
    <div>
      <select v-model="uYear" id="yearInput" class="form-control" required>
        <option selected disabled value>ปปปป</option>
        <option v-for="n in years" :key="'y_' + n" :value="n">
          {{ n }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      daysList: 31,
      uYear: "",
      uMonth: "",
      uDay: "",
    };
  },
  computed: {
    years() {
      const startYr = new Date().getFullYear() - 1;
      const endYr = new Date().getFullYear() + 1;
      let arr = [];
      for (let i = startYr; i <= endYr; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  watch: {
    uYear() {
      this.getMaxDaysInMonth(this.uMonth, this.uYear);
    },
    uMonth() {
      this.getMaxDaysInMonth(this.uMonth, this.uYear);
    },
  },
  methods: {
    getMaxDaysInMonth(month, year) {
      this.daysList = new Date(year, month, 0).getDate();
      if (this.daysList < this.uDay) {
        this.uDay = "";
      }
    },
  },
  mounted() {
    this.uYear = new Date().getFullYear();
    this.uMonth = new Date().getMonth() + 1;
    this.uDay = new Date().getDate();
  },
};
</script>

<style lang="scss" scoped>
.groupDate {
  display: flex;
  justify-content: center;
  width: 100% !important;
  > div {
    margin: 0 8px;
  }
}

#monthInput {
  width: 60px;
}

#dayInput {
  width: 60px;
}

#yearInput {
  width: 80px;
}

select {
  color: rgb(68, 68, 68);
  display: block;
  padding: 10px 8px;
  width: 100%;
  font-size: 16px;
  max-width: 300px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 6px;
  min-height: 45px;
  margin: 0 auto;
}
</style>