<template>
  <form id="informForm" class="informPaymentPage">
    <NavbarBack :title="'แจ้งชำระเงิน'" />
    <div class="container">
      <div class="group">
        <label>ช่องทางชำระเงิน</label>
        <select
          v-model="methodId"
          @change="selectedMethod()"
          required
          name="shopPaymentMethodId"
        >
          <option value disabled selected>เลือกช่องทาง:</option>
          <option
            :value="pm.id"
            v-for="(pm, index) of paymentMethods"
            :key="index"
          >
            {{ pm.paymentMethod.name }}
          </option>
        </select>
      </div>
      <!-- for cheque -->
      <div
        v-if="
          paymentMethodSelected.paymentMethod &&
          paymentMethodSelected.paymentMethod.slug === 'cheque'
        "
      >
        <div class="group">
          <label>ธนาคาร</label>
          <select id="banksSelectInput" required name="bankId">
            <option value disabled selected>เลือกธนาคาร:</option>
            <option
              :value="bank.id"
              v-for="(bank, index) of banks"
              :key="index"
            >
              {{ bank.name }}
            </option>
          </select>
        </div>
        <div class="group">
          <label>สาขา</label>
          <input type="text" required name="branchName" />
        </div>
        <div class="group">
          <label>เลขที่เช็ค</label>
          <input type="tel" required name="chequeNo" />
        </div>
      </div>
      <div class="group" v-else-if="paymentMethodSelected.paymentMethod &&
          paymentMethodSelected.paymentMethod.slug === 'promptpay'">
          <label>บัญชี</label>
          <select
            id="promptpaySelectInput"
            v-model="methodDetailId"
            required
            name="shopPaymentMethodDetailId"
          >
            <option value disabled selected>เลือกบัญชี:</option>
            <option
              v-for="(pmd, index) of paymentMethodsDetails"
              :value="pmd.id"
              :key="index"
            >
            {{ pmd.accountName }} - {{ pmd.accountNo }}
            </option>
          </select>
      </div>
      <div class="group" v-else-if="paymentMethodSelected.paymentMethod &&
          paymentMethodSelected.paymentMethod.slug === 'transfer'">
        <label>ธนาคาร</label>
        <select
          id="banksSelectInput"
          v-model="methodDetailId"
          required
          name="shopPaymentMethodDetailId"
        >
          <option value disabled selected>เลือกธนาคาร:</option>
          <option
            v-for="(pmd, index) of paymentMethodsDetails"
            :value="pmd.id"
            :key="index"
          >
            {{ pmd.bank.name }}: {{ pmd.accountName }}, {{ pmd.accountNo }}
          </option>
        </select>
        <div class="showMethodsDetail" v-if="showMethodsDetail">
          <div class="bankDetail">
            <img :src="showMethodsDetail.bank.logo" />
            <b
              >{{ showMethodsDetail.bank.name }}
              {{ showMethodsDetail.branchName }}</b
            >
          </div>
          <b>{{ showMethodsDetail.accountName }}</b
          ><br />
          <b>{{ showMethodsDetail.accountNo }}</b>
        </div>
      </div>
      <div class="group">
        <label>จำนวนเงิน (บาท)</label>
        <input
          type="number"
          step=".01"
          :value="netPrice"
          required
          name="amount"
        />
      </div>
      <div class="group">
        <label>วันที่ (วัน/เดือน/ปี ค.ศ.)</label>
        <DateDropdown ref="date" required />
      </div>
      <div class="group">
        <label>เวลา</label>
        <div class="timeInputGroup">
          <select v-model="hours" id="dayInput" class="form-control" required>
            <option selected disabled value>วว</option>
            <option v-for="(n, index) in 24" :key="'d_' + index" :value="index">
              {{ index >= 10 ? index : "0" + index }}
            </option>
          </select>
          :
          <select v-model="minute" id="dayInput" class="form-control" required>
            <option selected disabled value>วว</option>
            <option v-for="(n, index) in 60" :key="'d_' + index" :value="index">
              {{ index >= 10 ? index : "0" + index }}
            </option>
          </select>
        </div>
      </div>
      <div class="group">
        <label>หมายเหตุ</label>
        <textarea
          class="timeInputGroup"
          rows="3"
          v-model="note"
          name="note"
        ></textarea>
      </div>
      <UploadSlip ref="uploadSlip" :name="'transferSlip'" />
      <div class="group">
        <div v-if="$isBuyer" @click="confirmInform()" class="btn comfirmBtn">
          ส่งข้อมูลแจ้งชำระเงิน
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import DateDropdown from "@/components/DateDropdown";
import UploadSlip from "./components/UploadSlip";

import { GET, POST } from "@/utils/http";

export default {
  props: {
    sellerId: String,
    orderId: String,
    netPrice: {
      default: "",
      type: String,
    },
  },
  components: { NavbarBack, DateDropdown, UploadSlip },
  data() {
    return {
      methodId: null,
      methodDetailId: "",
      hours: 0,
      minute: 0,
      paymentMethods: [],
      paymentMethodsDetails: [],
      banks: [],
      note: "",
    };
  },
  computed: {
    showMethodsDetail() {
      return this.paymentMethodsDetails.find(
        (e) => e.id == this.methodDetailId
      );
    },
    paymentMethodSelected() {
      return this.paymentMethods.find((e) => e.id == this.methodId) || {};
    },
  },
  methods: {
    async getPaymentMethods() {
      this.methodDetailId = 0;
      this.methodId = 0;
      const { status, body } = await GET(
        `/api/shops/${this.sellerId}/payment_methods`
      );
      if (status !== 200) {
        throw "payment_methods: not ok";
      }
      this.paymentMethods = body.filter((pm) => pm.is_enable);
    },
    async getPaymentMethodDetail() {
      this.methodDetailId = 0;
      this.paymentMethodsDetails = [];
      if (this.methodId == 0) return;
      const { status, body } = await GET(
        `/api/shops/${this.sellerId}/shop_payment_methods/${this.methodId}`
      );
      if (status !== 200) {
        throw "payment_methods: not ok";
      }
      this.paymentMethodsDetails = body;
    },
    async getBanks() {
      const { status, body } = await GET(`/api/banks`);
      if (status !== 200) {
        throw "get banks: not ok";
      }
      this.banks = body;
    },
    selectedMethod() {
      if (
        this.paymentMethodSelected.paymentMethod &&
          this.paymentMethodSelected.paymentMethod.slug === "cheque"  
      ) {
        this.handleFetch(this.getBanks());
        return;
      }
      this.handleFetch(this.getPaymentMethodDetail());
    },
    async handleFetch(fn) {
      try {
        window.loading(true);
        await fn;
      } catch (err) {
        console.error(err);
        await window.$alert("พบข้อผิดพลาดการขอข้อมูล");
        this.$router.back();
      } finally {
        window.loading(false);
      }
    },
    confirmInform() {
      this.handleFetch(this.send());
    },
    addZero(n) {
      return parseInt(n) >= 10 ? n : "0" + n;
    },
    async send() {
      const form = document.getElementById("informForm");

      if (!form.checkValidity()) {
        window.$alert("กรุณากรอกข้อมูลให้ครบ");
        return;
      }

      if (!(await window.$alert("ต้องการแจ้งชำระหรือไม่?", "confirm"))) {
        return;
      }

      // prepare form data
      const formData = new FormData(form);

      const transferTime = `${this.$refs.date.uYear}-${this.addZero(
        this.$refs.date.uMonth
      )}-${this.addZero(this.$refs.date.uDay)}T${this.addZero(
        this.hours
      )}:${this.addZero(this.minute)}:00+07:00`;

      formData.append("transferTime", transferTime);
      if (this.$refs.uploadSlip.result.fileName) {
        formData.append(
          "transferSlip",
          this.$refs.uploadSlip.result.file,
          this.$refs.uploadSlip.result.fileName
        );
      }

      if (this.methodId == 0) return;
      const { status } = await POST(
        `/api/shops/${this.$myShop.id}/partners/seller/${this.sellerId}/orders/${this.orderId}/payments`,
        formData
      );
      window.loading(false);

      if (status !== 200) {
        throw "post payments inform: not ok";
      }

      await window.$alert("แจ้งชำระเงินสำเร็จ");

      this.$router.replace("/order/" + this.orderId);
    },
  },
  mounted() {
    this.hours = new Date().getHours();
    this.minute = new Date().getMinutes();
    this.handleFetch(this.getPaymentMethods());
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  overflow: auto;
  padding: 40px 15px 20px 15px;
  color: rgb(68, 68, 68);
}

.comfirmBtn {
  margin: 30px auto 20px auto;
  background: var(--primary-color);
  color: #fff;
}

.group {
  margin: 20px auto;
  label {
    display: block;
    margin-bottom: 8px;
  }
}

select {
  color: rgb(68, 68, 68);
  background: white;
}

select,
input,
textarea {
  padding: 10px 8px;
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 8px;
  min-height: 45px;
  color: rgb(63, 63, 63);
  display: block;
  margin: 0 auto;
}

.showMethodsDetail {
  text-align: center;
  margin-top: 6px;
  line-height: 24px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(221, 221, 221);
  img {
    width: 20px;
    margin-right: 4px;
  }
  .bankDetail {
    display: flex;
    justify-content: center;
  }
}

.timeInputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > select {
    margin: 0 10px;
    width: 70px;
    text-align: center;
  }
}
</style>